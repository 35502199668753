class TipoCargo {
  Id = "";
  Descripcion = "";
  EmpresaId = "";
  MEmpresa = Empresa;
  Estado = 0;
}
class Sucursal {
  Id = "";
  Descripcion = "";
  EmpresaId = "";
  Empresa = Empresa;
  MunicipioId = "";
  Municipio = Municipio;
  Estado = 0;
}

class Departamento {
  Id = "";
  Descripcion = "";
  SucursalId = "";
  Sucursal = Sucursal;
  EmpresaId = "";
  Empresa = Empresa;
  Estado = 0;
}
class Area {
  Id = "";
  Descripcion = "";
  DepartamentoId = "";
  Departamento = Departamento;
  EmpresaId = "";
  Empresa = Empresa;
  Estado = 0;
}

class MCargo {
  Id = "";
  Nombre = "";
  CargoDependienciaId = "";
  AreaId = "";
  Area = Area;
  SucursalId = "";
  Sucursal = Sucursal;
  TipoCargoId = "";
  TipoCargo = TipoCargo;
  Estado = 0;
}

class CargoCompetencia {
  Id = "";
  CompetenciaId = "";
  MCompetencia = Competencia;
  CargoId;
  Cargo = MCargo;
}
class MCicloEvaluacionCompetencia {
  Id = "00000000-0000-0000-0000-000000000000";
  CompetenciaId = "00000000-0000-0000-0000-000000000000";
  CicloEvaluacionId = "00000000-0000-0000-0000-000000000000";
}
class NivelUsuario {
  administradorhelexium = 4;
  superadministrador = 3;
  administrador = 2;
  usuario = 1;
}
class Continente {
  Id = "";
  Descripcion = "";
  Estado = 0;
}
class Pais {
  Id = "";
  Descripcion = "";
  ContinenteId = "";
  Continente = Continente;
  Estado = 0;
}

class DepartamentoPais {
  Id = "";
  Descripcion = "";
  PaisId = "";
  Pais = Pais;
  Estado = 0;
}

class Municipio {
  Id = "";
  Descripcion = "";
  DepartamentoPaisId = "";
  MDepartamentoPais = DepartamentoPais;

  PaisId = "";
  Pais = Pais;
  Estado = 0;
}
class TipoDocumento {
  Id = "";
  Descripcion = "";
  Estado = 1;
}
class Rol {
  Id = "";
  Descripcion = "";
  Nivel = 1;
  Estado = 0;
}
class Persona {
  Id = "";

  NumeroDocumento = "";
  Nombres = "";
  Apellidos = "";
  Direccion = "";
  TelefonoFijo = "";
  TelefonoMovil = "";
  Contrasena = "";
  ContrasenaReportes = 0;
  Correo = "";
  FechaNacimiento = new Date();
  FechaContratacion = new Date();
  UltimoIngreso = new Date();
  JefeDirectoId = "";
  EstadoUsuario = 1;
  Estado = 1;
  NivelReporte = 0;

  EmpresaId = "";
  Empresa = Empresa;

  TipoDocumentoId = "";
  MTipoDocumento = TipoDocumento;

  MunicipioId = "";
  Municipio = Municipio;

  DepartamentoPaisId = "";
  DepartamentoPais = DepartamentoPais;

  PaisId = "";
  Pais = Pais;

  RolId = "";
  Rol = Rol;

  EstadoCivilId = "";
  EstadoCivil = EstadoCivil;
}
class EstadoCivil {
  Id = "";
  Descripcion = "";
}
class CicloEvaluacionUsuario {
  Id = "00000000-0000-0000-0000-000000000000";
  RolEvaluacion = 0;
  PerPrincipal = "";
  Estado = 0;
  CicloEvaluacionId = "";
  PersonaId = "";
}

class CicloEvaluacion {
  Nombre = "";
  Descripcion = "";
  FechaDesde = "";
  FechaHasta = "";
  PonderaConductuales = 0;
  PonderaFuncionales = 0;
  PonderaMetas = 0;
  FechaIngreso = "";
  TipoEvaluacion = 0;
  TipoCompetencia = 0;
  EmpresaId = "";
}

class Sector {
  Id = "";
  Descripcion = "";
  Estado = "";
}

class Empresa {
  Id = "";
  Nombre = "";
  Correo = "";
  NombreContacto = "";
  TelefonoContacto = "";
  UrlLogo = "";
  ContrasenaIFrame = "";
  Estado = "";
  SectorId = "";
  Sector = Sector;
}
class TipoCompetencia {}

class Competencia {
  Id = "";
  Titulo = "";
  Descripcion = "";
  TipoCompetenciaId = "";
  TipoCompetencia = TipoCompetencia;
  EmpresaId = "";
  Empresa = Empresa;
  Estado = 0;
}

class cicloEvaluacionCompetencia {
  Id = "";
  CompetenciaId = "";
  MCompetencia = Competencia;
  CicloEvaluacionId = "";
  CicloEvaluacion = "";
}

export { CicloEvaluacion, Sector, Empresa, Competencia, TipoCompetencia, cicloEvaluacionCompetencia, NivelUsuario, CicloEvaluacionUsuario, Persona, MCicloEvaluacionCompetencia, CargoCompetencia };
