<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template>
            <div v-if="visible" class="p-1 container-fluid">
              <div v-if="vista == 0" class="p-4">
                <form class="px-4 text-left" @submit.prevent="Continuar(1)">
                  <div v-if="tabs == 1">
                    <div class="px-4">
                      <b
                        ><p style="font-size: 18px" class="text-center">
                          NUEVO CICLO
                        </p></b
                      >
                      <p style="font-size: 12px" class="text-center">v1.0106</p>
                      <b
                        ><p style="font-size: 16px" class="text-left mt-3">
                          <b>Información General</b>
                        </p></b
                      >

                      <div class="row">
                        <!-- nombre ciclo -->
                        <div class="col-6">
                          <div class="mb-2 row">
                            <label class="">Nombre del ciclo:</label>
                            <div class="col-12">
                              <input
                                required
                                v-model="row.NombreCiclo"
                                type="text"
                                class="form-control"
                                id="inputname"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- empresa -->
                        <div class="col-6">
                          <div class="mb-2 row">
                            <label class="">Empresa:</label><br />
                            <div
                              id="empresa"
                              class="col-12"
                              v-if="PersonaAct.empresa"
                            >
                              <select
                                required
                                v-if="
                                  PersonaAct
                                    ? PersonaAct.rol
                                      ? true
                                      : false
                                    : false
                                "
                                :disabled="true"
                                class="form-control py-0 form-select"
                                v-model="row.EmpresaId"
                              >
                                -
                                <option
                                  v-for="emp in listaEmpresas"
                                  :key="emp.id"
                                  :value="emp.id"
                                >
                                  {{ emp.nombre }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- descripción -->
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                              >Descripción:</label
                            >
                            <textarea
                              required
                              v-model="row.DescripcionCiclo"
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="mb-2 row">
                            <label class="">Fecha inicial:</label>
                            <div class="col-12">
                              <input
                                :min="row.FechaInicioCiclo"
                                required
                                v-model="row.FechaInicioCiclo"
                                type="datetime-local"
                                class="form-control"
                                id=""
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="mb-2 row">
                            <label>Fecha final:</label>
                            <div class="col-12">
                              <input
                                required
                                :min="row.FechaInicioCiclo"
                                v-model="row.FechaFinalCiclo"
                                type="datetime-local"
                                class="form-control"
                                id=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mt-4">
                        <div class="col-6">
                          <div class="form-check">
                            <input
                              @change="SetTipoEvaluacion(1)"
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault001"
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault001"
                            >
                              Evaluaciones Transversales
                            </label>
                          </div>
                        </div>
                        <div class="col-6 px-0">
                          <div class="form-check">
                            <input
                              @change="SetTipoEvaluacion(0)"
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault002"
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault002"
                            >
                              Evaluaciones por Cargo
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="bg-dark mt-3 p-4">
                    <b
                      ><p style="font-size: 18px" class="text-left">
                        Elegir evaluaciones
                      </p></b
                    >
                    <div class="p-3">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="card">
                            <div class="card-body text-center">
                              <h5 class="card-title pb-3">Autoevaluación</h5>
                              <span class=" ">
                                <div class="form-check">
                                  <input
                                    @change="SetTipoEvaluacionCiclo(1)"
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault2"
                                    id="flexRadioDefault01"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexRadioDefault01"
                                  >
                                    Elegir evaluación
                                  </label>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="card">
                            <div class="card-body text-center">
                              <h5 class="card-title pb-3">
                                Autoevaluación + jefe
                              </h5>
                              <span class=" ">
                                <div class="form-check">
                                  <input
                                    @change="SetTipoEvaluacionCiclo(2)"
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault2"
                                    id="flexRadioDefault02"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexRadioDefault02"
                                  >
                                    Elegir evaluación
                                  </label>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="card">
                            <div class="card-body text-center">
                              <h5 class="card-title pb-3">
                                Autoevaluación 360
                              </h5>
                              <span class=" ">
                                <div class="form-check">
                                  <input
                                    @change="SetTipoEvaluacionCiclo(3)"
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault2"
                                    id="flexRadioDefault03"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexRadioDefault03"
                                  >
                                    Elegir evaluación
                                  </label>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bg-dark mt-3 p-4">
                    <b
                      ><p style="font-size: 18px" class="text-left">
                        Ponderados
                      </p></b
                    >
                    <div class="p-3">
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="card">
                            <div class="card-body text-center">
                              <h5 class="card-title pb-3">
                                Metas <br />
                                <span class="mt-2"
                                  ><b> {{ ponderados[0].value }}% </b></span
                                >
                              </h5>
                              <span class="card-text"></span>
                              <span class=" ">
                                <div class="form-check form-switch">
                                  <input
                                    @change="SetPonderados($event, 0)"
                                    class="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault0"
                                  />
                                  <label class="form-check-label">
                                    <input
                                      :disabled="
                                        ponderados[0].active == false
                                          ? true
                                          : false
                                      "
                                      v-model="ponderados[0].value"
                                      type="number"
                                      class="form-control"
                                      id="exampleFormControlInput1"
                                      placeholder=""
                                    />
                                  </label>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="card">
                            <div class="card-body text-center">
                              <h5 class="card-title pb-3">
                                Conductuales <br />
                                <span class="mt-2"
                                  ><b> {{ ponderados[1].value }}% </b></span
                                >
                              </h5>
                              <span class="card-text"></span>
                              <span class=" ">
                                <div class="form-check form-switch">
                                  <input
                                    @change="SetPonderados($event, 1)"
                                    class="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault2"
                                  />
                                  <label class="form-check-label">
                                    <input
                                      :disabled="
                                        ponderados[1].active == false
                                          ? true
                                          : false
                                      "
                                      v-model="ponderados[1].value"
                                      type="number"
                                      class="form-control"
                                      id="exampleFormControlInput12"
                                      placeholder=""
                                    />
                                  </label>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="card">
                            <div class="card-body text-center">
                              <h5 class="card-title pb-3">
                                Funcionales <br />
                                <span class="mt-2"
                                  ><b> {{ ponderados[2].value }}% </b></span
                                >
                              </h5>
                              <span class="card-text"></span>
                              <span class=" ">
                                <div class="form-check">
                                  <div class="form-check form-switch">
                                    <input
                                      @change="SetPonderados($event, 2)"
                                      class="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault3"
                                    />
                                    <label class="form-check-label">
                                      <input
                                        :disabled="
                                          ponderados[2].active == false
                                            ? true
                                            : false
                                        "
                                        v-model="ponderados[2].value"
                                        type="number"
                                        class="form-control"
                                        id="exampleFormControlInput13"
                                        placeholder=""
                                      />
                                    </label>
                                  </div>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="card">
                            <div class="card-body">
                              <h5 class="card-title pb-3">
                                Total de ponderados <br />
                                <span class="mt-2"
                                  ><b>
                                    {{
                                      ponderados.reduce(
                                        (a, b) => a + Number(b.value),
                                        0
                                      )
                                    }}% de 100%
                                  </b></span
                                >
                              </h5>
                              <span class="card-text"></span>
                              <span class=" ">
                                <span
                                  >Recuerde que debe usar el 100%, si sobrepasa
                                  esta rango no podra continuar</span
                                >
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      ponderados.reduce((a, b) => a + Number(b.value), 0) > 100
                    "
                    style="background-color: #efb39d; height: 50px"
                  >
                    <h5 class="p-2">Ha sobrepasado el límite máximo.</h5>
                  </div>
                  <div class="bg-dark mt-3 p-4" v-if="verCompetencias">
                    <b
                      ><p style="font-size: 18px" class="text-left">
                        Evaluaciones Transversales
                      </p></b
                    >
                    <div class="table-responsive p-3">
                      <table
                        id="datatable_ev"
                        class="table table-bordered table-responsive-md table-striped text-center"
                      >
                        <thead>
                          <tr>
                            <!-- <th scope="col">#</th> -->
                            <th scope="col">Titulo</th>
                            <th scope="col">Tipo</th>
                            <th scope="col">Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="compe of ListCompetencias" :key="compe.id">
                            <!-- <td>{{ compe.Id }}</td> -->
                            <th scope="row">{{ compe.titulo }}</th>
                            <td>{{ compe.tipoCompetencia.descripcion }}</td>
                            <td>
                              <input
                                @click="LlenarList($event, compe)"
                                type="checkbox"
                                name=""
                                :id="'chk-' + compe.id"
                              />
                              Añadir competencia
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="text-right">
                    <input
                      class="btn btn-blue"
                      type="submit"
                      value="Continuar Registro"
                    />
                    <input
                      class="btn btn-gray my-4 mx-2"
                      type="submit"
                      value="Cancelar Registro"
                    />
                  </div>
                </form>
              </div>

              <!-- parte 2 -->
              <div v-if="vista == 1" class="bg-dark p-4">
                <form
                  class="px-4 text-left"
                  @submit.prevent="guardarCicloEvaluacionUsuario()"
                >
                  <div class="text-right">
                    <button class="btn btn-blue" type="submit">
                      Completar Registro
                    </button>
                  </div>

                  <!-- evaluaciones auto -->

                  <!-- {{ "..." + currentPaginationNumber0 }} -->

                  <div
                    class="col-6 mt-3"
                    v-if="cicloEv.TipoEvaluacion == 1"
                  ></div>
                  <v-client-table
                    v-if="cicloEv.TipoEvaluacion == 1"
                    v-model="ListPersona"
                    :columns="headersPersonaPrincipalAuto"
                    :options="options1"
                  >
                    <div slot="evaluado" slot-scope="props">
                      <div
                        v-if="
                          props.row.nivel != NivelUsuario.superadministrador &&
                          props.row.nivel != NivelUsuario.administradorhelexium
                        "
                      >
                        <span style="">{{
                          props.row.nombres + " " + props.row.apellidos
                        }}</span>
                      </div>
                    </div>

                    <div slot="autoevaluacion" slot-scope="props">
                      <div
                        v-if="
                          props.row.nivel != NivelUsuario.superadministrador &&
                          props.row.nivel != NivelUsuario.administradorhelexium
                        "
                      >
                        <span>{{
                          props.row.nombres + " " + props.row.apellidos
                        }}</span>
                      </div>
                    </div>

                    <div slot="selauto" slot-scope="props">
                      <div
                        v-if="
                          props.row.nivel != NivelUsuario.superadministrador &&
                          props.row.nivel != NivelUsuario.administradorhelexium
                        "
                      >
                        <div class="form-check form-switch">
                          <label
                            class="form-check-label"
                            :for="'aflexSwitchCheckDefault'"
                          >
                          </label>
                          <input
                            :checked="props.row.autoEvaluacion > 0"
                            @change="
                              SeleccionarAutoyJefe($event, props.row, 'Auto')
                            "
                            class="form-check-input"
                            type="checkbox"
                            :id="'aflexSwitchCheckDefault'"
                          />
                        </div>
                      </div>
                    </div>
                  </v-client-table>
                  <!-- evaluaciones auto+jefe -->
                  <div
                    class="col-6 mt-3"
                    v-if="cicloEv.TipoEvaluacion == 2"
                  ></div>

                  <v-client-table
                    v-if="cicloEv.TipoEvaluacion == 2"
                    v-model="ListPersona"
                    :columns="headersPersonaPrincipalAutoJefe"
                    :options="options2"
                  >
                    <div slot="evaluado" slot-scope="props">
                      <div
                        v-if="
                          props.row.nivel != NivelUsuario.superadministrador &&
                          props.row.nivel != NivelUsuario.administradorhelexium
                        "
                      >
                        <span style="">{{
                          props.row.nombres + " " + props.row.apellidos
                        }}</span>
                      </div>
                    </div>

                    <div slot="autoevaluacion" slot-scope="props">
                      <div
                        v-if="
                          props.row.nivel != NivelUsuario.superadministrador &&
                          props.row.nivel != NivelUsuario.administradorhelexium
                        "
                      >
                        <span>{{
                          props.row.nombres + " " + props.row.apellidos
                        }}</span>
                      </div>
                    </div>

                    <div slot="jefe" slot-scope="props">
                      <div
                        v-if="
                          props.row.nivel != NivelUsuario.superadministrador &&
                          props.row.nivel != NivelUsuario.administradorhelexium
                        "
                      >
                        <span>{{ props.row.jefeDirecto }}</span>
                      </div>
                    </div>
                    <div slot="selautojefe" slot-scope="props">
                      <div
                        v-if="
                          props.row.nivel != NivelUsuario.superadministrador &&
                          props.row.nivel != NivelUsuario.administradorhelexium
                        "
                      >
                        <div class="form-check form-switch">
                          <label
                            class="form-check-label"
                            :for="'aflexSwitchCheckDefault'"
                          >
                          </label>
                          <VueToggles
                            height="20"
                            width="50"
                            :value="props.row.autoEvaluacion > 0 ? true : false"
                            @click="
                              SeleccionarAutoyJefe(
                                $event,
                                props.row,
                                'Autoyjefe'
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </v-client-table>

                  <!-- evaluaciones 360 -->
                  <div
                    class="col-6 mt-3"
                    v-if="cicloEv.TipoEvaluacion == 3"
                  ></div>

                  <v-client-table
                    v-if="cicloEv.TipoEvaluacion == 3"
                    v-model="ListPersona"
                    :columns="headersPersonaPrincipal"
                    :options="options3"
                  >
                    <div slot="evaluado" slot-scope="props">
                      <div
                        v-if="
                          props.row.nivel != NivelUsuario.superadministrador &&
                          props.row.nivel != NivelUsuario.administradorhelexium
                        "
                      >
                        <span
                          style="cursor: pointer"
                          @click="mostrarModal(true, props.row)"
                          >{{
                            props.row.nombres + " " + props.row.apellidos
                          }}</span
                        >
                      </div>
                    </div>

                    <div slot="autoevaluacion" slot-scope="props">
                      <div
                        v-if="
                          props.row.nivel != NivelUsuario.superadministrador &&
                          props.row.nivel != NivelUsuario.administradorhelexium
                        "
                      >
                        <span>{{
                          props.row.nombres + " " + props.row.apellidos
                        }}</span>
                      </div>
                    </div>

                    <div slot="jefe" slot-scope="props">
                      <div
                        v-if="
                          props.row.nivel != NivelUsuario.superadministrador &&
                          props.row.nivel != NivelUsuario.administradorhelexium
                        "
                      >
                        <span>{{ props.row.jefeDirecto }}</span>
                      </div>
                    </div>
                    <div slot="selcompa" slot-scope="props">
                      <div
                        v-if="
                          props.row.nivel != NivelUsuario.superadministrador &&
                          props.row.nivel != NivelUsuario.administradorhelexium
                        "
                      >
                        <div class="form-check form-switch">
                          <input
                            :checked="
                              props.row.cantidadEvaluadores > 0 ? true : false
                            "
                            @change="mostrarModal($event, props.row)"
                            class="form-check-input"
                            type="checkbox"
                            :id="'flexSwitchCheckDefault'"
                          />
                          <label
                            class="form-check-label"
                            :for="'flexSwitchCheckDefault'"
                          >
                          </label>
                          {{ props.row.cantidadEvaluadores }}<br />
                        </div>
                      </div>
                    </div>

                    <div slot="selautojefe" slot-scope="props">
                      <div
                        v-if="
                          props.row.nivel != NivelUsuario.superadministrador &&
                          props.row.nivel != NivelUsuario.administradorhelexium
                        "
                      >
                        <div>
                          <VueToggles
                            height="20"
                            width="50"
                            :value="props.row.autoEvaluacion > 0 ? true : false"
                            @click="
                              SeleccionarAutoyJefe(
                                $event,
                                props.row,
                                'Autoyjefe'
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </v-client-table>

                  <div
                    v-if="OpenClose"
                    class="modal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-modal="true"
                    role="dialog"
                    style="display: block"
                  >
                    <div
                      class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable"
                    >
                      <div class="modal-content">
                        <div class="modal-header textcenter">
                          <span class="" style="font-weight: bold !important">{{
                            "Usuario a evaluar: " +
                            personaSel.nombres +
                            " " +
                            personaSel.apellidos
                          }}</span>
                          <!-- <button type="button" @click="closeModal()" class="btn-close"></button> -->
                        </div>
                        <div
                          class="modal-body"
                          style="height: 500px !important"
                        >
                          <span><b>Filtrar:</b></span>
                          <div class="row">
                            <div class="col-6 mt-1">
                              <select
                                v-model="filaFiltros.sucursal"
                                @change="selSucursal($event)"
                                class="form-control py-0 form-select mb-2"
                              >
                                <option selected disabled value="0">
                                  Selecciona Sucursal
                                </option>
                                <option value="0">Ver todos</option>
                                <option
                                  v-for="suc in listaSucursales"
                                  :key="suc.id"
                                  :value="suc.id"
                                >
                                  {{ suc.descripcion }}
                                </option>
                              </select>
                            </div>
                            <div class="col-6 mt-1">
                              <select
                                v-model="filaFiltros.departamento"
                                :disabled="departamentoSelect"
                                @change="selDepto($event)"
                                class="form-control py-0 form-select mb-2"
                              >
                                <option selected disabled value="0">
                                  Selecciona Depto.
                                </option>
                                <option
                                  v-for="dep in listaDepartamentos"
                                  :key="dep.id"
                                  :value="dep.id"
                                >
                                  {{ dep.descripcion }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6 mt-1">
                              <select
                                v-model="filaFiltros.area"
                                :disabled="areaSelect"
                                @change="buscarPorArea($event)"
                                class="form-control py-0 form-select mb-2"
                              >
                                <option selected disabled value="0">
                                  Selecciona Area
                                </option>
                                <option
                                  v-for="are in listaAreas"
                                  :key="are.id"
                                  :value="are.id"
                                >
                                  {{ are.descripcion }}
                                </option>
                              </select>
                            </div>
                            <div class="col-6 mt-1">
                              <!-- <input @keyup="buscar($event)" placeholder="Ingresa texto a buscar..." type="text" v-model="searchValue" class="form-control mb-2" /> -->
                              <input
                                v-if="departamentoSelect"
                                placeholder="Ingresa texto a buscar..."
                                class="form-control mb-2"
                                type="text"
                                v-model="searchValue"
                              />
                            </div>
                          </div>
                          <!-- busqueda manual -->
                          <div class="row"></div>
                          <div class="row">
                            <div class="col-12"></div>
                          </div>
                          <hr
                            style="margin-top: 5px; border-top: 1px solid black"
                          />

                          <v-client-table
                            ref="modaltable"
                            v-model="ListPersonaModal"
                            :columns="headersPersona"
                            :options="options"
                          >
                            <div slot="accion" slot-scope="props">
                              <div class="form-check form-switch">
                                <input
                                  :checked="
                                    props.row.cicloEvaluacionUsuarioId !=
                                      null &&
                                    props.row.cicloEvaluacionUsuarioId != ''
                                      ? true
                                      : false
                                  "
                                  @change="SeleccionarPar($event, props.row)"
                                  class=""
                                  type="checkbox"
                                  :id="'flexSwitchCheckDefault'"
                                />
                              </div>
                            </div>

                            <div slot="nombres" slot-scope="props">
                              <div>
                                <span style="font-weight: bold !important">{{
                                  props.row.nombres + " " + props.row.apellidos
                                }}</span>
                              </div>
                            </div>
                          </v-client-table>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            @click="closeModal()"
                            class="btnblue"
                          >
                            Cerrar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </template>
        </card>
      </b-col>
    </b-row>
    <b-modal
      id="my-modal-errores"
      size="lg"
      title="Errores encontrados al crear ciclo de evaluación"
      hide-footer
    >
      <div class="text-center">
        <h3 class="text-white"></h3>
        <div class="mt-3">
          <table class="table">
            <thead class="">
              <tr class="">
                <th class="" scope="col">CicloEvaluacionUsuarioId</th>
                <th class="" scope="col">Nombre Evaluado</th>
                <th class="" scope="col">Detalle</th>
                <th class="" scope="col">Tipo Ev.</th>
              </tr>
            </thead>
            <tbody
              class="border"
              v-for="(itemTable, i) of usuariosProblema"
              :key="i"
            >
              <template>
                <tr>
                  <td class="border">{{ itemTable.cicloEvaluacionUsuario }}</td>
                  <td class="border">{{ itemTable.persona }}</td>
                  <td class="border">{{ itemTable.detalle }}</td>
                  <td class="border">
                    {{
                      itemTable.tipoEv == 0
                        ? "AutoEvaluacion"
                        : itemTable.tipoEv == 1
                        ? "Jefe"
                        : "Par"
                    }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import Vue from "vue";
import { ClientTable } from "vue-tables-2-premium";
Vue.use(ClientTable);
import Swal from "sweetalert2";
import {
  CicloEvaluacion,
  CicloEvaluacionUsuario,
  NivelUsuario,
  MCicloEvaluacionCompetencia,
  CargoCompetencia,
} from "../../../Model/Models";
import moment from "moment-timezone";
import { core } from "../../../config/pluginInit";
import { computed } from "vue";
const currentPaginationNumber = computed(() => {
  return 2;
});

export default {
  data() {
    return {
      options: {
        filterable: [
          "nombres",
          "apellidos",
          "areaId",
          "departamentoId",
          "sucursalId",
        ],
      },
      moment: moment,
      id: "",
      items: [],
      item: {},
      tabs: "1",
      user: {},
      visible: true,
      file1: null,
      finename1: null,
      documento: "",
      listaEmpresas: [],
      PersonaAct: {
        empresa: {},
        // rol:{nivel:0}
      },
      row: {
        EmpresaId: "",
      },
      verCompetencias: false,
      ListCompetencias: [],
      options1: {
        headings: {
          evaluado: "evaluado",
          autoevaluacion: "Auto evaluación",
          selauto: "Seleccionar",
        },
        filterable: [
          "nombres",
          "apellidos",
          "areaId",
          "departamentoId",
          "sucursalId",
        ],
      },
      headersPersonaPrincipalAuto: ["evaluado", "autoevaluacion", "selauto"],

      headers: [
        { text: "Título", value: "titulo" },
        { text: "Descripción", value: "descripcion" },
        { text: "Acción", value: "" },
      ],
      options2: {
        headings: {
          evaluado: "Evaluado",
          autoevaluacion: "Auto evaluación",
          jefe: "Jefe",
          selautojefe: "Seleccionar Auto+Jefe",
        },
        filterable: [
          "nombres",
          "apellidos",
          "areaId",
          "departamentoId",
          "sucursalId",
        ],
      },
      headersPersonaPrincipalAutoJefe: [
        "evaluado",
        "autoevaluacion",
        "jefe",
        "selautojefe",
      ],

      options3: {
        headings: {
          evaluado: "Evaluado",
          autoevaluacion: "Auto evaluación",
          jefe: "Jefe",
          selcompa: "Selecciona Par",
          selautojefe: "Seleccionar Auto+Jefe",
        },
        filterable: [
          "nombres",
          "apellidos",
          "areaId",
          "departamentoId",
          "sucursalId",
        ],
      },
      headersPersonaPrincipal: [
        "evaluado",
        "autoevaluacion",
        "jefe",
        "selcompa",
        "selautojefe",
      ],
      headersPersona: ["accion", "nombres"],
      EvComptList: [],
      ListaEvaluacionCompetencia: [], //models.CicloEvaluacionCompetencia
      ponderados: [
        //metas, conductuales funcionales
        { id: 0, active: false, value: 0 },
        { id: 1, active: false, value: 0 },
        { id: 2, active: false, value: 0 },
      ],
      vista: 0,
      cicloEvaluacionId: "", // "3F1860D1-28FB-4339-9F6E-A4DDA6C807C9",
      //cicloEvaluacionId: "6D7F0645-D8E6-484A-B6E3-EBD46DBFE3C1",
      cicloEv: new CicloEvaluacion(),
      ListPersona: [],
      ListPersonaModal: [],
      searchValue: "",
      NivelUsuario: new NivelUsuario(),
      OpenClose: false,
      personaSel: {},
      itemsSelected: [],
      cicloEvaluacionUsuario: new CicloEvaluacionUsuario(),
      listaTmpUsuarios: [],
      mensajeBusqueda: "",
      Areas: [],
      departamentoSelect: true,
      areaSelect: true,
      listaSucursales: [],
      listaDepartamentos: [],
      listaAreas: [],
      filaFiltros: {
        sucursal: 0,
        departamento: 0,
        area: 0,
      },
      searchValuePrincipal: "",
      searchValueTesting: "",
      currentPaginationNumber0: currentPaginationNumber,
      searchField: [
        "nombres",
        "apellidos",
        "areaId",
        "departamentoId",
        "sucursalId",
      ],
      initialSearch: "",
      /////////////
      CicloEvaluacionUsuarioToVerifica: [],
      usuariosProblema: [],
    };
  },
  async mounted() {
    this.id = this.$route.params.user;
    this.documento = ""; //this.$route.params.document;
    this.row.TipoEvaluacionCiclo = 0;
    await this.getEmpresas();
    this.PersonaAct = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.row.EmpresaId = this.PersonaAct.empresa.id; //"5D8FB6CD-F685-4E49-8227-9A7B424B9B80";
    this.row.FechaInicioCiclo = this.$moment
      .tz(new Date(), "America/Costa_Rica")
      .format("YYYY-MM-DDTHH:mm");
    this.row.FechaFinalCiclo = this.$moment
      .tz(new Date(), "America/Costa_Rica")
      .format("YYYY-MM-DDTHH:mm");
    this.visible = true;
    await this.getListaCompetencias();
  },
  methods: {
    showToast(type, message) {
      console.log(type);
      if (type == "success") {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (type == "error")
        this.$toast.error(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      if (type == "warning")
        this.$toast.warning(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
    },
    async busquedaFormPrincipal() {
      //console.log("event? ", e);
      //console.log("busqueda normal");
      //console.log(this.searchValuePrincipal);
      let resulta = this.ListPersona.filter(
        (item) =>
          item.nombres
            .toUpperCase()
            .includes(this.searchValuePrincipal.toUpperCase()) ||
          item.apellidos
            .toUpperCase()
            .includes(this.searchValuePrincipal.toUpperCase())
      );
      //console.log(resulta);
      if (resulta.length > 0) {
        this.ListPersona = resulta;
        // this.mensajeBusqueda = resulta.length + " coincidencia(s)";
      } else {
        this.ListPersona = this.listaTmpUsuarios;
        // this.mensajeBusqueda = "Sin resultados.";
      }
      if (this.searchValuePrincipal == "") {
        //this.ListPersonaModal = this.listaTmpUsuarios;
        // this.mensajeBusqueda = "Sin resultados.";
        //this.mostrarModal(true, this.personaSel);
        await this.GetListPersona();
      }
    },
    notify(text, type) {
      if (type == 1) this.showToast("success", text);

      //   toast.success(text, {
      //     rtl: true,
      //     autoClose: 1000,
      //     position: "top-center",
      //   });
      if (type == 2) this.showToast("warning", text);
      //   toast.warning(text, {
      //     rtl: true,
      //     autoClose: 1000,
      //     position: "top-center",
      //   });
    },
    async selSucursal(e) {
      console.log(e.target.value);
      if (e.target.value == 0) {
        this.mostrarModal(true, this.personaSel);
        this.filaFiltros.departamento = 0;
        this.filaFiltros.area = 0;
        this.departamentoSelect = true;
        this.areaSelect = true;
        this.searchValue = "";
        this.$refs.modaltable.setFilter("");
      } else {
        await this.getDepto(e.target.value);
        this.departamentoSelect = false;
        this.filaFiltros.departamento = 0;
        this.filaFiltros.area = 0;
      }
    },
    async selDepto(e) {
      console.log(e.target.value);
      await this.getArea(e.target.value);
      this.searchValue = e.target.value;
      this.$refs.modaltable.setFilter(e.target.value);

      this.areaSelect = false;
      this.filaFiltros.area = 0;
    },
    async getArea(e) {
      this.$isLoading(true);

      try {
        console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          // path: "PersonaUbicacion/List/" + this.row.EmpresaId
          path: "Area/ListAreaDepartamento/" + e,
        });
        this.$isLoading(false);

        console.log("get listaAreas ...", res);
        if (res.length > 0) {
          this.listaAreas = res;
          this.searchValue = e;
          this.$refs.modaltable.setFilter(e);
        } else {
          this.listaAreas = [];
        }
      } catch (error) {
        this.$isLoading(false);

        this.listaAreas = [];
        //console.log("err", error);
      }
    },
    async getSucursales() {
      try {
        //   //console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          // path: "PersonaUbicacion/List/" + this.row.EmpresaId
          path: "Sucursal/ListSucursalEmpresa/" + this.row.EmpresaId,
        });
        //aqui debería filtrar lista de usuarios x sucursal
        //console.log('lista personas modal para revisar datos...',this.ListPersonaModal)
        ////console.log("get listaSucursales ...", res);
        if (res.length > 0) {
          this.listaSucursales = res;
        } else {
          this.listaSucursales = [];
        }
      } catch (error) {
        this.listaSucursales = [];
        console.log("err", error);
      }
    },
    async getDepto(e) {
      this.$isLoading(true);

      try {
        console.log("get depto...");
        let res = await this.$store.dispatch("hl_get", {
          // path: "PersonaUbicacion/List/" + this.row.EmpresaId
          path: "Departamento/ListDepartamentoSucursal/" + e,
        });
        //aqui debería filtrar lista de usuarios x sucursal
        //console.log('lista personas modal para revisar datos...',this.ListPersonaModal)

        this.$isLoading(false);

        console.log("get ListDepartamento ...", res);
        if (res.length > 0) {
          this.searchValue = e;
          this.$refs.modaltable.setFilter(e);
          this.listaDepartamentos = res;
        } else {
          this.listaDepartamentos = [];
          this.searchValue = "";
          this.$refs.modaltable.setFilter("");
        }
      } catch (error) {
        this.$isLoading(false);
        this.listaDepartamentos = [];
        console.log("err", error);
      }
    },

    async buscarPorArea(e) {
      console.log("busca por area ", e.target.value);
      this.$refs.modaltable.setFilter(e.target.value);
      this.searchValue = e.target.value;
    },
    async buscar() {
      //console.log("event? ", e);
      // if (e == null) {
      //console.log("busqueda normal");
      //console.log(this.searchValue);
      let resulta = this.ListPersonaModal.filter(
        (item) =>
          item.nombres.toUpperCase().includes(this.searchValue.toUpperCase()) ||
          item.apellidos.toUpperCase().includes(this.searchValue.toUpperCase())
      );
      //console.log(resulta);
      if (resulta.length > 0) {
        this.ListPersonaModal = resulta;
        this.mensajeBusqueda = resulta.length + " coincidencia(s)";
      } else {
        this.ListPersonaModal = this.listaTmpUsuarios;
        this.mensajeBusqueda = "Sin resultados.";
      }
      if (this.searchValue == "") {
        //this.ListPersonaModal = this.listaTmpUsuarios;
        this.mensajeBusqueda = "Sin resultados.";
        this.mostrarModal(true, this.personaSel);
      }
    },
    async CreateCicloEvaluacionCompetencia() {
      let NewListCicloCompetencia = [];
      //let CicloResult = false;
      let TipoCiclo = this.cicloEv.TipoCompetencia;

      if (TipoCiclo == 0) {
        return true;
      } else if (TipoCiclo == 1) {
        if (this.EvComptList.length > 0) {
          for await (let CEItem of this.EvComptList) {
            let CicloEvCompe = new MCicloEvaluacionCompetencia();
            CicloEvCompe.cicloEvaluacionId = this.cicloEvaluacionId;
            CicloEvCompe.CompetenciaId = CEItem.id;
            NewListCicloCompetencia.push(CicloEvCompe);
          }
        } else {
          return true;
        }
        await this.$store.dispatch("hl_post", {
          path: "CicloEvaluacionCompetencia/CreateCicloEvaluacionCompetencia",
          data: NewListCicloCompetencia,
        });
        //console.log("cicloresult.......", CicloResult);
        // CicloCompetenciaService.CreateCicloEvaluacionCompetencia(NewListCicloCompetencia);
      }
    },
    hideModal() {
      alert("hidemodal");
    },
    async obtenerCicloEvaluacion() {
      this.cicloEv = await this.$store.dispatch("hl_get", {
        path: "CicloEvaluacion/GetCicloEvaluacion/" + this.cicloEvaluacionId,
      });
      //console.log("cicloev", this.cicloEv);
    },
    async getCicloEvaluacionUsuarioLista(cicloId) {
      console.log("get getCicloEvaluacionUsuarioLista....", cicloId);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "CicloEvaluacionUsuario/GetUsuariosByCicloEvaluacionId/" + cicloId,
        });
        this.CicloEvaluacionUsuarioToVerifica = res;
      } catch (error) {
        console.log("err", error);
      }
    },
    async getPersona(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/GetPersona/" + personaId,
        });
        return res;
      } catch (error) {
        console.log("err", error);
        return null;
      }
    },
    async verificarCiclo() {
      try {
        this.$isLoading(true);
        //console.log("item", item);
        this.usuariosProblema = [];
        //obtener lista de usuarios perprincipal del ciclo seleccionado
        await this.getCicloEvaluacionUsuarioLista(this.cicloEvaluacionId);
        console.log(
          "getCicloEvaluacionUsuarioLista",
          this.CicloEvaluacionUsuarioToVerifica
        );
        for await (let cicloEvaluacionUsuario of this
          .CicloEvaluacionUsuarioToVerifica) {
          console.log("getCicloEvaluacionUsuarioLista", cicloEvaluacionUsuario);
          // console.log('this.cicloEv.TipoCompetencia', this.cicloEv.TipoCompetencia)
          console.log(
            "this.cicloEv.TipoCompetencia",
            this.cicloEv.tipoCompetencia
          );
          console.log("this.cicloEv.TipoCompetencia", this.cicloEv);

          if (this.cicloEv.tipoCompetencia == 0) {
            console.log(
              "tipo competencia == 0",
              cicloEvaluacionUsuario.perPrincipal
            );
            let persona = await this.getPersona(
              cicloEvaluacionUsuario.perPrincipal
            );
            let ubicacion = await this.$store.dispatch("hl_get", {
              path:
                "PersonaUbicacion/GetByPersona/" +
                cicloEvaluacionUsuario.perPrincipal,
            });
            let CargoCompt = await this.$store.dispatch("hl_get", {
              path: "CargoCompetencia/ListByCargo/" + ubicacion.cargo.id,
            });
            let CicloCompt = await this.$store.dispatch("hl_get", {
              path:
                "CicloEvaluacionCompetencia/ListCompetenciaByCiclo/" +
                this.cicloEvaluacionId,
            });
            console.log("--->ca1", CargoCompt);
            console.log("--->ca2", CicloCompt);
            if (CicloCompt.length == 0) {
              this.usuariosProblema.push({
                cicloEvaluacionUsuario: cicloEvaluacionUsuario.id,
                persona: persona.nombres + " " + persona.apellidos,
                detalle:
                  "Usuario evaluado no posee competencias en ciclo, evaluación sin preguntas.",
                tipoEv: cicloEvaluacionUsuario.rolEvaluacion,
              });
            }
            if (CargoCompt.length == 0) {
              this.usuariosProblema.push({
                cicloEvaluacionUsuario: cicloEvaluacionUsuario.id,
                persona: persona.nombres + " " + persona.apellidos,
                detalle: "Cargo de usuario evaluado sin competencias.",
                tipoEv: cicloEvaluacionUsuario.rolEvaluacion,
              });
            }
            console.log(this.usuariosProblema);
          } else {
            console.log("tipo competencia > 0", this.cicloEvaluacionId);
            let res = await this.$store.dispatch("hl_get", {
              path:
                "CicloEvaluacionCompetencia/ListCompetenciaByCiclo/" +
                this.cicloEvaluacionId,
            });
            console.log(res);
          }
        }
        this.$isLoading(false);
        if (this.usuariosProblema.length == 0) {
          return false;
          //Swal.fire("Listo!", "Ciclo no posee errores!.", "success");
        } else {
          return true;
          //this.$bvModal.show("my-modal-errores");
        }
      } catch (error) {
        this.$isLoading(false);
        Swal.fire("Error!", "Error en verificación de Ciclo.", "error");

        console.log("error", error);
      }
    },
    async guardarCicloEvaluacionUsuario() {
      this.$isLoading(true);

      try {
        await this.obtenerCicloEvaluacion();
        //obtener informacion del ciclo, por si hubo desconexión lo obtengo de la db
        if (this.cicloEv.tipoCompetencia == 0) {
          let NewListCicloCompetencia = []; // new MCicloEvaluacionCompetencia();
          //console.log(NewListCicloCompetencia);
          let NewCicloUserList = await this.$store.dispatch("hl_get", {
            path:
              "CicloEvaluacionUsuario/GetUsuariosByCicloEvaluacionId/" +
              this.cicloEvaluacionId,
          });
          //con esta lista NewCicloUserList puedo enviar notificaciones al correo, entonces debo iterar... pero debo evitar repetir notif.
          let copiaNewCicloUserList = [...NewCicloUserList];
          //const unique = [...new Set(copiaNewCicloUserList.map(item => item.PersonaId))]; // [ 'A', 'B']

          const key = "personaId";

          const unique = [
            ...new Map(
              copiaNewCicloUserList.map((item) => [item[key], item])
            ).values(),
          ];

          console.log(unique);

          console.log("aqui va unique************************", unique);
          for await (var Personaid of unique) {
            console.log("iterando usuarios....", Personaid);
            await this.$store.dispatch("hl_get", {
              path:
                "CicloEvaluacionUsuario/EnvioNotificacionEvaluacionUsuario/" +
                Personaid.personaId,
            });
          }

          console.log("usuarios... ", NewCicloUserList);

          for await (var Usuario of NewCicloUserList) {
            console.log("Usuario iterando ", Usuario);
            //abr: 03122023 siguiente linea comentada porque cuando se selecciona 360 y no se asigna auto+jefe no se guardan las competencias.
            //if (Usuario.rolEvaluacion == 0) {
            //PersonaUbicacion/GetByPersona/
            //console.log("Usuario.PersonaId", Usuario.personaId);
            let UsuarioData = await this.$store.dispatch("hl_get", {
              path: "PersonaUbicacion/GetByPersona/" + Usuario.perPrincipal,
            }); // GetByPersona(Usuario.PersonaId);
            //console.log("personaId in for", UsuarioData);
            if (UsuarioData != null) {
              let CargoId = UsuarioData.cargo.id;
              //console.log(CargoId);
              let ListCargoCompetencia = new CargoCompetencia();
              ListCargoCompetencia = await this.$store.dispatch("hl_get", {
                path: "CargoCompetencia/ListByCargo/" + CargoId,
              });
              console.log("listacargocompetencia ", ListCargoCompetencia);

              if (ListCargoCompetencia.length > 0) {
                for await (let Item of ListCargoCompetencia) {
                  console.log(
                    "iterando lista MCicloEvaluacionCompetencia -->",
                    Item
                  );
                  //console.log("iterando lista", this.cicloEv.id);
                  let NewItem = new MCicloEvaluacionCompetencia();
                  NewItem.CompetenciaId = Item.competencia.id;
                  NewItem.CicloEvaluacionId = this.cicloEv.id;
                  NewListCicloCompetencia.push(NewItem);
                }
              } else {
                console.log("else 1");
              }
            } else {
              console.log("else2");
            }
            //} else {
            //  console.log("else3");
            //}
          }
          console.log("*************", NewListCicloCompetencia);
          // var CicloResult = CicloCompetenciaService.CreateCicloEvaluacionCompetencia(NewListCicloCompetencia);

          await this.$store.dispatch("hl_post", {
            path: "CicloEvaluacionCompetencia/CreateCicloEvaluacionCompetencia",
            data: NewListCicloCompetencia,
          });
          //console.log(cicloevcompresult);
          this.$isLoading(false);
          let resultaVerifica = await this.verificarCiclo();
          if (resultaVerifica) {
            this.$bvModal.show("my-modal-errores");
            // setTimeout(() => {
            //   this.$router.push("/helex/Ciclo_evaluaciones");
            // }, 3000);
          } else {
            Swal.fire("Ciclo almacenado correctamente!", "", "info");
            setTimeout(() => {
              this.$router.push("/helex/Ciclo_evaluaciones");
            }, 3000);
          }
        }
      } catch (error) {
        console.log("error", error);
        this.$isLoading(false);
      }
    },
    async SeleccionarAutoyJefe(e, it, tipo) {
      if (e == true || e == false) {
        e = !e;
      }

      console.log(e);
      console.log(it);
      console.log(tipo);
      try {
        if (tipo == "Auto") {
          console.log(e.target.checked);
          //console.log(it);
          this.cicloEvaluacionUsuario.Estado = 1;
          this.cicloEvaluacionUsuario.CicloEvaluacionId =
            this.cicloEvaluacionId;
          this.cicloEvaluacionUsuario.PerPrincipal = it.id;
          this.cicloEvaluacionUsuario.PersonaId = it.id;
          //0 autoev, 1 jefe, 2 par
          let dataAuto = { ...this.cicloEvaluacionUsuario };
          dataAuto.RolEvaluacion = 0;
          let dataJefe = { ...this.cicloEvaluacionUsuario };
          dataJefe.PersonaId = it.jefeDirectoId;
          dataJefe.RolEvaluacion = 1;

          if (e == true || e.target.checked) {
            //console.log("1");
            //insertar autevaluacion
            await this.$store.dispatch("hl_post", {
              path: "CicloEvaluacionUsuario/CreateCicloEvaluacionUsuariov2",
              data: dataAuto,
            });
            this.notify("AutoEvaluación almacenada correctamente", 1);

            //insertar jefe
            // await this.$store.dispatch("hl_post", { path: "CicloEvaluacionUsuario/CreateCicloEvaluacionUsuariov2", data: dataJefe });
          } else {
            //console.log("2");
            //eliminar
            await this.$store.dispatch("hl_delete", {
              path: "CicloEvaluacionUsuario/DeleteCicloEvaluacionUsuario",
              data: dataAuto,
            });
            this.notify("AutoEvaluación eliminada", 2);
            // await this.$store.dispatch("hl_delete", { path: "CicloEvaluacionUsuario/DeleteCicloEvaluacionUsuario", data: dataJefe });
          }
        }
        if (tipo == "jefe") {
          //console.log("3");
          //console.log(e.target.checked);
          //console.log(it);
          this.cicloEvaluacionUsuario.Estado = 1;
          this.cicloEvaluacionUsuario.CicloEvaluacionId =
            this.cicloEvaluacionId;
          this.cicloEvaluacionUsuario.PerPrincipal = it.id;
          this.cicloEvaluacionUsuario.PersonaId = it.id;
          //0 autoev, 1 jefe, 2 par
          let dataAuto = { ...this.cicloEvaluacionUsuario };
          dataAuto.RolEvaluacion = 0;
          let dataJefe = { ...this.cicloEvaluacionUsuario };
          dataJefe.PersonaId = it.jefeDirectoId;
          dataJefe.RolEvaluacion = 1;

          if (e == true || e.target.checked) {
            //console.log("4");
            //insertar autevaluacion
            await this.$store.dispatch("hl_post", {
              path: "CicloEvaluacionUsuario/CreateCicloEvaluacionUsuariov2",
              data: dataAuto,
            });
            //insertar jefe
            this.notify("AutoEvaluación almacenada correctamente", 1);
            await this.$store.dispatch("hl_post", {
              path: "CicloEvaluacionUsuario/CreateCicloEvaluacionUsuariov2",
              data: dataJefe,
            });
            this.notify("Evaluación Jefe almacenada correctamente", 1);
          } else {
            //eliminar
            await this.$store.dispatch("hl_delete", {
              path: "CicloEvaluacionUsuario/DeleteCicloEvaluacionUsuario",
              data: dataAuto,
            });
            this.notify("Autoevaluación eliminada", 2);
            await this.$store.dispatch("hl_delete", {
              path: "CicloEvaluacionUsuario/DeleteCicloEvaluacionUsuario",
              data: dataJefe,
            });
            this.notify("Evaluación Jefe eliminada", 2);
          }
        }

        if (tipo == "Autoyjefe") {
          //console.log(e.target.checked);
          //console.log(it);
          this.cicloEvaluacionUsuario.Estado = 1;
          this.cicloEvaluacionUsuario.CicloEvaluacionId =
            this.cicloEvaluacionId;
          this.cicloEvaluacionUsuario.PerPrincipal = it.id;
          this.cicloEvaluacionUsuario.PersonaId = it.id;
          //0 autoev, 1 jefe, 2 par
          let dataAuto = { ...this.cicloEvaluacionUsuario };
          dataAuto.RolEvaluacion = 0;
          let dataJefe = { ...this.cicloEvaluacionUsuario };
          dataJefe.PersonaId = it.jefeDirectoId;
          dataJefe.RolEvaluacion = 1;

          if (e == true) {
            //insertar autevaluacion
            await this.$store.dispatch("hl_post", {
              path: "CicloEvaluacionUsuario/CreateCicloEvaluacionUsuariov2",
              data: dataAuto,
            });
            this.notify("AutoEvaluación almacenada correctamente", 1);
            //insertar jefe
            await this.$store.dispatch("hl_post", {
              path: "CicloEvaluacionUsuario/CreateCicloEvaluacionUsuariov2",
              data: dataJefe,
            });
            this.notify("Evaluación Jefe almacenada correctamente", 1);
          }
          if (e == false) {
            //eliminar
            await this.$store.dispatch("hl_delete", {
              path: "CicloEvaluacionUsuario/DeleteCicloEvaluacionUsuario",
              data: dataAuto,
            });
            this.notify("Autoevaluación eliminada", 2);
            await this.$store.dispatch("hl_delete", {
              path: "CicloEvaluacionUsuario/DeleteCicloEvaluacionUsuario",
              data: dataJefe,
            });
            this.notify("Evaluación Jefe eliminada", 2);
          }
        }
        await this.GetListPersonav2();
      } catch (error) {
        console.log("error", error);
      }
    },
    async SeleccionarPar(e, it) {
      try {
        this.cicloEvaluacionUsuario.Estado = 1;
        this.cicloEvaluacionUsuario.CicloEvaluacionId = this.cicloEvaluacionId;
        this.cicloEvaluacionUsuario.PersonaId = it.id;
        this.cicloEvaluacionUsuario.PerPrincipal = this.personaSel.id;
        //0 autoev, 1 jefe, 2 par
        this.cicloEvaluacionUsuario.RolEvaluacion = 2;

        if (e == true || e.target.checked) {
          //insertar
          await this.$store.dispatch("hl_post", {
            path: "CicloEvaluacionUsuario/CreateCicloEvaluacionUsuariov2",
            data: this.cicloEvaluacionUsuario,
          });
          this.notify("Evaluación de compañero almacenada correctamente", 1);
        } else {
          //eliminar
          await this.$store.dispatch("hl_delete", {
            path: "CicloEvaluacionUsuario/DeleteCicloEvaluacionUsuario",
            data: this.cicloEvaluacionUsuario,
          });
          this.notify("Evaluación de compañero eliminada", 2);
        }
        /////30032023/////////////////////
        this.$isLoading(true);

        let ListPersonaModal = await this.$store.dispatch("hl_get", {
          path:
            "CicloEvaluacionUsuario/ListCicloEvaluacionByCicloId/" +
            this.cicloEvaluacionId +
            "/" +
            this.row.EmpresaId +
            "/" +
            this.personaSel.id,
        });
        ListPersonaModal = ListPersonaModal.filter(
          (item) =>
            item.tipoDocumentoId != "00000000-0000-0000-0000-000000000000" &&
            item.id != this.personaSel.id &&
            item.nivel != this.NivelUsuario.superadministrador &&
            item.nivel != this.NivelUsuario.administradorhelexium &&
            item.id != this.personaSel.jefeDirectoId
        );
        setTimeout(() => {
          this.ListPersonaModal = [];
          this.ListPersonaModal = ListPersonaModal;
          this.listaTmpUsuarios = [...this.ListPersonaModal];
        }, 500);
        this.$isLoading(false);
        /////////////////////////////
        //await this.mostrarModal(true, this.personaSel);
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },
    async closeModal() {
      await this.GetListPersona();
      this.OpenClose = false;
    },
    async mostrarModal(e, per) {
      this.$isLoading(true);
      try {
        console.log("per", e);
        if (e == true || e.target.checked) {
          //en lugar de este codigo debo hacer un select left join entre personas y el ciclo ev.
          this.personaSel = per;
          //console.log("1");
          let ListPersonaModal = await this.$store.dispatch("hl_get", {
            path:
              "CicloEvaluacionUsuario/ListCicloEvaluacionByCicloId/" +
              this.cicloEvaluacionId +
              "/" +
              this.row.EmpresaId +
              "/" +
              this.personaSel.id,
          });
          console.log("2");
          ListPersonaModal = ListPersonaModal.filter(
            (item) =>
              item.tipoDocumentoId != "00000000-0000-0000-0000-000000000000" &&
              item.id != this.personaSel.id &&
              item.nivel != this.NivelUsuario.superadministrador &&
              item.nivel != this.NivelUsuario.administradorhelexium &&
              item.id != this.personaSel.jefeDirectoId
          );
          this.ListPersonaModal = [];
          setTimeout(() => {
            this.ListPersonaModal = ListPersonaModal;
            this.listaTmpUsuarios = [...this.ListPersonaModal];
          }, 500);
          this.$isLoading(false);

          this.OpenClose = true;
        } else {
          this.$isLoading(false);
          Swal.fire({
            title: "¿Qué deseas hacer?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Editar compañeros",
            denyButtonText: `Eliminar compañeros`,
            cancelButtonText: `Cancelar`,
          }).then(async (result) => {
            //console.log("resulta --", result);
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              //editar compañeros
              //Swal.fire("Saved!", "", "success");
              this.personaSel = per;
              //console.log("1");
              let ListPersonaModal = await this.$store.dispatch("hl_get", {
                path:
                  "CicloEvaluacionUsuario/ListCicloEvaluacionByCicloId/" +
                  this.cicloEvaluacionId +
                  "/" +
                  this.row.EmpresaId +
                  "/" +
                  this.personaSel.id,
              });
              //console.log("2");
              ListPersonaModal = ListPersonaModal.filter(
                (item) =>
                  item.tipoDocumentoId !=
                    "00000000-0000-0000-0000-000000000000" &&
                  item.id != this.personaSel.id &&
                  item.nivel != this.NivelUsuario.superadministrador &&
                  item.nivel != this.NivelUsuario.administradorhelexium &&
                  item.id != this.personaSel.jefeDirectoId
              );
              this.ListPersonaModal = [];
              setTimeout(() => {
                this.ListPersonaModal = ListPersonaModal;
                this.listaTmpUsuarios = [...this.ListPersonaModal];
              }, 500);
              this.$isLoading(false);

              this.OpenClose = true;
            } else if (result.isDenied) {
              // eliminar compañeros
              this.personaSel = per;
              //console.log("1", per);
              let ListPersonaModal = await this.$store.dispatch("hl_get", {
                path:
                  "CicloEvaluacionUsuario/ListCicloEvaluacionByCicloId/" +
                  this.cicloEvaluacionId +
                  "/" +
                  this.row.EmpresaId +
                  "/" +
                  this.personaSel.id,
              });
              //console.log("2");
              ListPersonaModal = ListPersonaModal.filter(
                (item) =>
                  item.tipoDocumentoId !=
                    "00000000-0000-0000-0000-000000000000" &&
                  item.id != this.personaSel.id &&
                  item.nivel != this.NivelUsuario.superadministrador &&
                  item.nivel != this.NivelUsuario.administradorhelexium &&
                  item.id != this.personaSel.jefeDirectoId &&
                  item.cicloEvaluacionUsuarioId != null
              );
              this.$isLoading(false);

              //filtrar usuarios que tienen evaluaciones
              //console.log("lista persona modal", ListPersonaModal);

              ////////////////////////////
              for await (let itemEliminar of ListPersonaModal) {
                let cicloEvaluacionUsuario = {};
                cicloEvaluacionUsuario.Estado = 1;
                cicloEvaluacionUsuario.CicloEvaluacionId =
                  this.cicloEvaluacionId;
                cicloEvaluacionUsuario.PersonaId = itemEliminar.id;
                cicloEvaluacionUsuario.PerPrincipal = this.personaSel.id;
                //utoev, 1 jefe, 2 par
                cicloEvaluacionUsuario.RolEvaluacion = 2;
                //console.log("cicloev elimina", cicloEvaluacionUsuario);
                //insertar
                //eliminar
                await this.$store.dispatch("hl_delete", {
                  path: "CicloEvaluacionUsuario/DeleteCicloEvaluacionUsuario",
                  data: cicloEvaluacionUsuario,
                });
                //console.log("res ", resultaElimina);
                this.notify("Evaluación de compañero eliminada", 2);
              }
              await this.GetListPersona();
              /////////////////

              //Swal.fire("Compañeros eliminados correctamente", "", "info");
            } else if (result.isDismissed) {
              // cancelar
              // Swal.fire("Changes are not saved", "", "info");
              await this.GetListPersona();
            }
          });
        }
      } catch (error) {
        this.$isLoading(false);

        console.log("error", error);
      }
    },
    async GetListPersonav2() {
      this.$isLoading(true);

      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "CicloEvaluacionUsuario/ListCicloEvaluacionByCicloId/" +
            this.cicloEvaluacionId +
            "/" +
            this.row.EmpresaId,
        });
        res = res.filter(
          (item) =>
            item.tipoDocumentoId != "00000000-0000-0000-0000-000000000000" &&
            item.nivel != this.NivelUsuario.superadministrador &&
            item.nivel != this.NivelUsuario.administradorhelexium
        );

        this.$isLoading(false);

        res = res.sort((a, b) =>
          a.nombres.toLowerCase() > b.nombres.toLowerCase() ? 1 : -1
        );
        //this.ListPersona = [];
        this.ListPersona = await res;
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },
    async GetListPersona() {
      //console.log('***********************getlistpersona***************')
      this.$isLoading(true);

      try {
        //console.log("obteniendo personas");
        // let res = await this.$store.dispatch("hl_get", { path: "Persona/ListPersona/" + this.row.EmpresaId });
        this.ListPersona = [];
        let res = await this.$store.dispatch("hl_get", {
          path:
            "CicloEvaluacionUsuario/ListCicloEvaluacionByCicloId/" +
            this.cicloEvaluacionId +
            "/" +
            this.row.EmpresaId,
        });

        res = res.filter(
          (item) =>
            item.tipoDocumentoId != "00000000-0000-0000-0000-000000000000" &&
            item.nivel != this.NivelUsuario.superadministrador &&
            item.nivel != this.NivelUsuario.administradorhelexium
        );

        this.$isLoading(false);

        res = res.sort((a, b) =>
          a.nombres.toLowerCase() > b.nombres.toLowerCase() ? 1 : -1
        );
        this.ListPersona = await res;
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },
    selEmpresa(e) {
      console.log(e.target.value);
    },
    async Continuar(paso) {
      this.$isLoading(true);

      try {
        this.vista = paso;
        core.index();

        window.$("#dataTable1").DataTable({
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });

        this.cicloEv.Nombre = this.row.NombreCiclo;
        this.cicloEv.Descripcion = this.row.DescripcionCiclo;
        this.cicloEv.FechaDesde = this.row.FechaInicioCiclo;
        this.cicloEv.FechaHasta = this.row.FechaFinalCiclo;
        this.cicloEv.FechaIngreso = this.$moment
          .tz(new Date(), "America/Costa_Rica")
          .format("YYYY-MM-DDTHH:mm:ss");
        this.cicloEv.PonderaMetas = Number(this.ponderados[0].value);
        this.cicloEv.PonderaConductuales = Number(this.ponderados[1].value);
        this.cicloEv.PonderaFuncionales = Number(this.ponderados[2].value);
        this.cicloEv.TipoCompetencia = this.row.EvaluacionCiclo; // this.row.TipoEvaluacionCiclo;
        this.cicloEv.TipoEvaluacion = this.row.TipoEvaluacionCiclo; //this.row.EvaluacionCiclo;
        this.cicloEv.EmpresaId = this.row.EmpresaId;
        console.log("--->", this.cicloEv);
        let res = await this.$store.dispatch("hl_post", {
          path: "CicloEvaluacion/CreateCicloEvaluacion",
          data: this.cicloEv,
        });
        console.log("******** cicloevaluacion id: res", res);
        this.cicloEvaluacionId = res;
        //segunda parte, cargamos personas
        this.cicloEv.Id = res;
        await this.CreateCicloEvaluacionCompetencia();
        await this.GetListPersona();
        await this.getSucursales();
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        console.log(error);
      }
    },
    async SetPonderados(e, valor) {
      //console.log(valor);
      //console.log(e.target.checked);
      e = e.target.checked;
      this.ponderados[valor].active = e;
      //console.log(this.ponderados);
      //let totalPuntos = 100
      let totalActivos = this.ponderados.filter((x) => x.active == true);
      for await (let it of this.ponderados) {
        if (it.active == true)
          it.value = (100 / totalActivos.length).toFixed(0);
        else it.value = 0;
        if (totalActivos.length == 3 && it.id == 2) it.value = 34;
      }
      //console.log(totalActivos);
    },
    SetTipoEvaluacionCiclo(tipo) {
      // console.log('ok0')
      this.row.TipoEvaluacionCiclo = tipo;
      //console.log(this.row);
    },
    LlenarList(e, item) {
      //console.log(e.target.checked);
      //console.log(item);
      let buscarElemento = this.EvComptList.indexOf(item);
      //console.log("buscar elemento ", buscarElemento);
      if (buscarElemento == -1) {
        this.EvComptList.push(item);
      } else {
        this.EvComptList.splice(buscarElemento, 1);
      }
      //console.log("elemento full ", this.EvComptList);
    },
    async getListaCompetencias() {
      try {
        // core.index();
        //console.log("get empresas...");
        let res = await this.$store.dispatch("hl_post", {
          path: "Competencia/ListCompetencia",
        });
        // window.$("#datatable_ev").DataTable({
        //   language: {
        //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        //   },
        // });
        //console.log('post empresas...', res)
        if (res.length > 0) {
          this.ListCompetencias = res;

          //console.log(res);
        } else {
          this.ListCompetencias = [];
        }
      } catch (error) {
        this.ListCompetencias = [];
        console.log("err", error);
      }
    },
    SetTipoEvaluacion(item) {
      this.row.EvaluacionCiclo = item;
      console.log(this.row.EvaluacionCiclo);
      if (item == 0) this.verCompetencias = false;
      else {
        this.verCompetencias = true;
        setTimeout(() => {
          core.index();
          window.$("#datatable_ev").DataTable({
            language: {
              url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
            },
          });
          console.log("ok1");
        }, 1000);
      }
    },
    async getEmpresas() {
      //console.log('***********************getempresas***************')
      try {
        //console.log("get empresas...");
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/ListEmpresa",
        });
        ////console.log('post empresas...', res)
        if (res.length > 0) {
          this.listaEmpresas = res;
        } else {
          this.listaEmpresas = [];
        }
      } catch (error) {
        this.listaEmpresas = [];
        console.log("err", error);
      }
    },
    async login(Usuario, contrasena) {
      let data = JSON.stringify({ Usuario: Usuario, contrasena: contrasena });
      //console.log(data)
      try {
        let res = await this.$store.dispatch("hl_login", { data: data });
        //console.log("rs", res);
        if (res.id) {
          this.PersonaAct = res;
          return true;
        } else {
          this.PersonaAct = {};
          return false;
        }
      } catch (error) {
        this.PersonaAct = {};
        return false;
      }
    },
  },
};
</script>

<style scoped>
.btnblue {
  background-color: #7286ed;
  color: white !important;
  /* height: 40px !important; */
  border-radius: 5px;
  border-color: beige;
  padding: 8px;
  width: 100px;
}
.btn-blue {
  background-color: #7286ed;
  color: white !important;
  /* height: 40px !important; */
  border-radius: 5px;
  border-color: beige;
}
.btn-gray {
  background-color: #8d8e94;
  color: white !important;
  /* height: 40px !important; */
  border-radius: 5px;
  border-color: beige;
}

label {
  text-transform: lowercase !important;
}

label::first-letter {
  text-transform: uppercase;
}
.modal-content {
  box-shadow: 0 0.5rem 0.5rem rgb(0 0 0 / 5%) !important;
}
.textcenter {
  text-align: center !important;
  color: blue;
  align-items: center !important;
}
h5 {
  font-size: 1.1em;
}

.VuePagination {
  text-align: center;
}

.vue-title {
  text-align: center;
  margin-bottom: 10px;
}

.vue-pagination-ad {
  text-align: center;
}

.glyphicon.glyphicon-eye-open {
  width: 16px;
  display: block;
  margin: 0 auto;
}

th:nth-child(3) {
  text-align: center;
}

.VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

.VueTables__child-row-toggler--closed::before {
  content: "+";
}

.VueTables__child-row-toggler--open::before {
  content: "-";
}

[v-cloak] {
  display: none;
}
.custom-control-inline {
  display: block !important;
}
</style>
